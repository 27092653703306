

import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";

import { UserService } from "../service/user.service";

import { ConvertDate, HelpStatus, ConvertDate2 } from "../utility/help";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";


export enum helpView {
  none = "none",
  write = "write",
  view = "view",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }


  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
          style={{color:'#ffffff'}}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{color:'#ffffff'}}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{color:'#ffffff'}}>
          {row.isRead === "y" ? "확인"  : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>

            {/* {RenderRef()} */}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClosePopup: () => any;
  // handleDel: () => any;
  handleChange: (viewName: string) => any;

  note : any
  
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { helps: [], mode: helpView.view, title: "", contents: "" };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    // this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <div className="form-container">
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#692828" }}
            >
              <TableHead>
                <TableRow>
                  <CustomTableCell align="center"></CustomTableCell>
                  <CustomTableCell align="center">제 목</CustomTableCell>
                  <CustomTableCell align="center">작성일</CustomTableCell>
                  <CustomTableCell align="center">상태</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.note.map((info: any) => (
                  <Row key={info.name} row={info} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
{/* 
          <div className="btn_wrap_center" style={{ marginTop: "10px" }}>
            <ul>
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ mode: helpView.write });
                  }}
                >
                  <span className="btn3_1">글쓰기</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <table
            style={{ width: "100%", border: "0" }}
            cellSpacing="0"
            cellPadding="0"
            className="input1"
          >
            <tbody>
              <tr>
                <td className="write_title">제목</td>
                <td className="write_basic">
                  <input
                    type="text"
                    className="input1"
                    onChange={(e) =>
                      this.setState({
                        title: e.target.value,
                      })
                    }
                    style={{ width: "500px" }}
                  />
                </td>
              </tr>
              <tr>
                <td className="write_title">내용</td>
                <td className="write_basic">
                  <textarea
                    className="input1"
                    rows={10}
                    onChange={(e) =>
                      this.setState({
                        contents: e.target.value,
                      })
                    }
                    placeholder="내용을 입력하세요"
                    style={{ width: "500px", height: "200px" }}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td className="write_basic" colSpan={2}>
                  <div className="btn_wrap_center">
                    <ul>
                      <li>
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.setState({ mode: helpView.view });
                          }}
                        >
                          <span className="btn3_1">뒤로가기</span>
                        </a>
                        &nbsp;&nbsp;
                        <a
                          href="javascript:;"
                          onClick={() => {
                            this.handleSaveHelp(
                              this.state.title,
                              this.state.contents
                            );
                          }}
                        >
                          <span className="btn3_1">저장하기</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClosePopup();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">
{/*                 
                <ShareMenu view={popupView.note} handleChange={this.props.handleChange}></ShareMenu> */}


               <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">쪽지함</div>
                    <div className="con_box10">
                      {/* Content start */}
                      {RenderView()}
                      {/* {RenderWrite()} */}
                      {/* Content end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
