import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { UserService } from "../service/user.service";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">
                <div className="popup_tab_wrap">
                  <ul className="popup_tab popup_tab1">
                    <li className="tab1" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.deposit); //입금신청
                        }}
                      >
                        <span>입금신청</span>
                      </a>
                    </li>
                    <li className="tab2" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.withdraw); //출금신청
                        }}
                      >
                        <span>출금신청</span>
                      </a>
                    </li>
                    <li className="tab4" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.point); //금고
                        }}
                      >
                        <span>금고</span>
                      </a>
                    </li>
                    <li className="tab5" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.help); //고객센터
                        }}
                      >
                        <span>고객센터</span>
                      </a>
                    </li>
                    <li
                      className="tab6 sk_tab_active_01"
                      data-target="#sk_tab_con_01_1"
                    >
                      <a href="javascript:;">
                        <span>마이페이지</span>
                      </a>
                    </li>
                    <li className="tab7" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.notice); //마이페이지
                        }}
                      >
                        <span>공지사항</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">마이페이지</div>
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">회원 기본정보</div>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">아이디</td>
                            <td className="write_basic">
                              {this.props.user.id}
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">보유머니</td>
                            <td className="write_basic">
                              {this.props.user.balance}
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">금고머니</td>
                            <td className="write_basic">
                              {this.props.user.point}
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">예금주명</td>
                            <td className="write_basic">
                              {this.props.user.bankowner}
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">회원은행</td>
                            <td className="write_basic">
                              {this.props.user.bankname}
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">출금 계좌번호</td>
                            <td className="write_basic">
                              {this.props.user.banknum}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">비밀번호 변경</div>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">새 비밀번호</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                type="password"
                                name="pass"
                                maxLength={15}
                                placeholder="새 비밀번호를 입력해주세요"
                                value={this.state.pass1}
                                onChange={(e: any) => {
                                  this.setState({ pass1: e.target.value });
                                }}
                                style={{ width: "250px" }}
                              />
                              <br />
                              최소 6자에서 최대 15자로 입력해주세요.
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">비밀번호 확인</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                type="password"
                                name="pass2"
                                maxLength={15}
                                placeholder="새 비밀번호를 다시 입력해주세요"
                                value={this.state.pass2}
                                onChange={(e: any) => {
                                  this.setState({ pass2: e.target.value });
                                }}
                                style={{ width: "250px" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_basic" colSpan={2}>
                              <span
                                className="error"
                                style={{ display: "none" }}
                              >
                                위에 입력하신 비밀번호와 일치하지 않습니다.
                              </span>
                              <span
                                className="granted"
                                style={{ display: "none" }}
                              >
                                위에 입력하신 비밀번호와 일치합니다.
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="write_basic" colSpan={2}>
                              <div className="btn_wrap_center">
                                <ul>
                                  <li>
                                    <a
                                      href="javascript:;"
                                      onClick={this.handleExchangeToPassword}
                                    >
                                      <span className="btn3_1">확인</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          //   <div>
          //     <div
          //       className="modal fade show"
          //       id="board_notice-modal"
          //       aria-labelledby="board_notice-modalLabel"
          //       aria-modal="true"
          //       role="dialog"
          //       style={{ display: "block" }}
          //     >
          //       <div className="modal-dialog modal-xl">
          //         <div className="modal-content">
          //           <div className="modal-header">
          //             <div className="modal-title-wrapper">
          //               <div className="modal-title-container">
          //                 <h5 className="title-text">마이페이지</h5>
          //               </div>
          //             </div>
          //             <button
          //               type="button"
          //               className="close"
          //               data-dismiss="modal"
          //               aria-label="Close"
          //               onClick={() => {
          //                 this.props.handleClose();
          //               }}
          //             >
          //               <span aria-hidden="true">
          //                 <img src="images/x-button.png" />
          //               </span>
          //             </button>
          //           </div>
          //           <div
          //             className="modal-body"
          //             style={{ width: "100%", height: "500px", overflow: "auto" }}
          //           >
          //             {/* Content Start */}

          //             <div className="mypage-wrapper">
          //               <div className="mypage-headtitle">
          //                 <div className="head-section-wrapper">
          //                   <div className="title-wrapper">
          //                     <div className="title-bg"></div>
          //                     <div className="title-icon-bg mypage_04 user_custom01"></div>
          //                     <p className="user_custom01">회원정보</p>
          //                   </div>
          //                 </div>
          //               </div>

          //               <div className="d-table text-center user_custom03">
          //                 <p className="id-info w-100 user_custom04">
          //                   <span id="id-print">{this.props.user.id}</span>
          //                   <span className="user_custom05">님</span>
          //                 </p>
          //                 <p className="w-100 user_custom06">
          //                   크라운에 오신것을 환영합니다!
          //                 </p>
          //                 <p className="w-100 user_custom07">
          //                   가입시 입력하신 회원님의 정보를 확인 하실 수 있습니다.
          //                   <br />
          //                   전화번호 및 출금계좌 변경은
          //                   <span className="user_custom08">1:1문의</span>를
          //                   이용해 주세요.
          //                 </p>
          //               </div>
          //               <div className="container-fluid page-wrapper_empty">
          //                 <table
          //                   className="stat_user_table"
          //                   cellSpacing="0"
          //                   cellPadding="0"
          //                   style={{ width: "100%", border: "0" }}
          //                 >
          //                   <thead>
          //                     <tr>
          //                       <td colSpan={3} scope="col" className="header">
          //                         회원 기본정보
          //                       </td>
          //                     </tr>
          //                   </thead>
          //                   <tbody>
          //                     <tr className="border-bt">
          //                       <th>아이디</th>
          //                       <td className="colone">:</td>
          //                       <td>
          //                         <div className="id-level" id="level-01"></div>
          //                         {this.props.user.id}
          //                       </td>
          //                     </tr>
          //                     <tr className="border-bt">
          //                       <th scope="row">보유머니</th>
          //                       <td className="colone">:</td>
          //                       <td>{this.props.user.balance}</td>
          //                     </tr>
          //                     <tr className="border-bt">
          //                       <th scope="row">금고머니</th>
          //                       <td className="colone">:</td>
          //                       <td>{this.props.user.point}</td>
          //                     </tr>
          //                     {/* <tr className="border-bt">
          //   <th scope="row">가입일</th>
          //   <td className="colone">:</td>
          //   <td>2020-12-23</td>
          // </tr> */}
          //                   </tbody>
          //                 </table>

          //                 <table
          //                   className="stat_user_table"
          //                   cellSpacing="0"
          //                   cellPadding="0"
          //                   style={{ width: "100%", border: "0" }}
          //                 >
          //                   <form
          //                     name="modify_form"
          //                     method="post"
          //                     target="process_frm"
          //                   ></form>
          //                   <input type="hidden" name="reg_type" value="2" />
          //                   <thead>
          //                     <tr>
          //                       <td scope="col" colSpan={3} className="header">
          //                         비밀번호 변경
          //                       </td>
          //                     </tr>
          //                   </thead>
          //                   <tbody>
          //                     <tr>
          //                       <th>새 비밀번호</th>
          //                       <td scope="col" colSpan={2} className="">
          //                         <input
          //                           className="mypage_pw"
          //                           type="password"
          //                           name="pass"
          //                           maxLength={15}
          //                           placeholder="새 비밀번호를 입력해주세요"
          //                           value={this.state.pass1}
          //                           onChange={(e: any) => {
          //                             this.setState({ pass1: e.target.value });
          //                           }}
          //                         />
          //                       </td>
          //                     </tr>
          //                     <tr>
          //                       <td></td>
          //                       <td scope="col" colSpan={3} className="stat-msg">
          //                         <span className="formal">
          //                           최소 6자에서 최대 15자로 입력해주세요.
          //                         </span>
          //                       </td>
          //                     </tr>
          //                     <tr>
          //                       <th>비밀번호 확인</th>
          //                       <td scope="col" colSpan={2} className="">
          //                         <input
          //                           className="mypage_pw"
          //                           type="password"
          //                           name="pass2"
          //                           maxLength={15}
          //                           placeholder="새 비밀번호를 다시 입력해주세요"
          //                           value={this.state.pass2}
          //                           onChange={(e: any) => {
          //                             this.setState({ pass2: e.target.value });
          //                           }}
          //                         />
          //                       </td>
          //                     </tr>
          //                     <tr>
          //                       <td></td>
          //                       <td scope="col" colSpan={3} className="stat-msg">

          //                       </td>
          //                     </tr>
          //                     <tr>
          //                       <td colSpan={3} className="text-center">
          //                         <button
          //                           className="btn_confirm"
          //                           onClick={this.handleExchangeToPassword}
          //                         >
          //                           확인
          //                         </button>
          //                       </td>
          //                     </tr>
          //                   </tbody>
          //                 </table>

          //                 <table
          //                   className="stat_user_table"
          //                   cellSpacing="0"
          //                   cellPadding="0"
          //                   style={{ width: "100%", border: "0" }}
          //                 >
          //                   <thead>
          //                     <tr>
          //                       <td colSpan={3} scope="col" className="header">
          //                         회원 기본정보
          //                       </td>
          //                     </tr>
          //                   </thead>
          //                   <tbody>
          //                     <tr className="border-bt">
          //                       <th>은행명</th>
          //                       <td className="colone">:</td>
          //                       <td>{this.props.user.bankname}</td>
          //                     </tr>
          //                     <tr className="border-bt">
          //                       <th>예금주</th>
          //                       <td className="colone">:</td>
          //                       <td>{this.props.user.bankowner}</td>
          //                     </tr>
          //                     <tr className="border-bt">
          //                       <th>출금 계좌번호</th>
          //                       <td className="colone">:</td>
          //                       <td>{this.props.user.banknum}</td>
          //                     </tr>
          //                   </tbody>
          //                 </table>
          //               </div>
          //             </div>

          //             {/* Content End */}
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
        )}
      </Popup>
    );
  }
}
