import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
  
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {balance: '0',};

  }


  handleRollingToBalance = () => {
  
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
        
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {

    
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">

                <ShareMenu view={popupView.comp} handleChange={this.props.handleChange}></ShareMenu>

                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">
                      콤프전환{" "}
                      <span style={{ fontSize: "14px", color: "#ff00f6" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;* 보이스피싱 및 3자사기로
                        협박하더라도 협상 및 타협 절대없음 *
                      </span>
                    </div>
                    {/* <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">주의사항</div>
                        <div className="info3">
                          - 계좌거래시 입금/출금 이름이 동일해야 입출금이
                          가능하오니 정확히 입력해 주세요.
                          <br />- 수표나 타인계좌로 이름변경하여 입금시 머니몰수
                          및 아이디정지 처리되오니 타인명의로 입금해야될시
                          문의를 먼저하시기 바랍니다
                        </div>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <div className="money">
                        <ul>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            보유머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.balance)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            콤프 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.rolling)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.props.updateUser();
                              }}
                            >
                              <span className="btn1_2">
                                <img src="/images/icon_re.png" height="20" />{" "}
                                새로고침
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">아이디</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                value={this.props.user.id}
                                readOnly
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="write_title">예금주명</td>
                            <td className="write_basic">
                              <input
                                className="input1 userName"
                                size={30}
                                value={this.props.user.bankowner}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">회원은행</td>
                            <td className="write_basic">
                              <input
                                className="input1 userBankName"
                                size={30}
                                value={this.props.user.bankname}
                                readOnly
                              />
                            </td>
                          </tr>
                       
                          <tr>
                            <td className="write_title">신청금액</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                id="deposit_amount"
                                placeholder="0"
                                value={this.state.balance}
                                onFocus={() =>  this.setState({balance: ''})}

                                onChange={(e) =>
                                  this.setState({
                                    balance: e.target.value,
                                  })
                                }
                              />
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 50000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">5만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 100000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">10만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 500000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">50만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 1000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">100만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 5000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">500만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1000만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({ balance: '0' });
                                }}
                              >
                                <span className="btn1_1">정정</span>
                              </a>
                              <br />
                              콤프전환은 1000원 단위이상 신청바랍니다.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="con_box20">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={this.handleRollingToBalance}
                            >
                              <span className="btn3_1">콤프전환</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

      
        )}
      </Popup>
    );
  }
}
