import React, { Component } from "react";
import Popup from "reactjs-popup";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
}

interface Props {
  handleClose: () => any;
  handleDel: (id: string) => any;
  handleReadNote: (id: string) => any;
  note: any;
}

interface State {
  mode: string;
  title: string;
  contents: string;
  id: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: noteView.view,
      title: "",
      contents: "",
      id: "",
    };
  }

  render() {
    const RenderView = () => {
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <table
          className="board-bg-global board-bg-list board-page-list2"
          cellSpacing="0"
          cellPadding="0"
          style={{ width: "100%", border: "0" }}
        >
          <thead>
            <tr>
              <td style={{ width: "40%" }}>
                제<span style={{ letterSpacing: "0" }}>목</span>
              </td>
              <td style={{ width: "20%" }}>작성일</td>
              <td style={{ width: "20%" }}>
                상<span style={{ letterSpacing: "0" }}>태</span>
              </td>
              <td style={{ width: "20%" }}>
                삭<span style={{ letterSpacing: "0" }}>제</span>
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.note.map((info: any) => (
              <tr>
                <td className="title text-truncate">
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.props.handleReadNote(info._id);
                      this.setState({
                        title: info.title,
                        contents: info.text,
                        mode: noteView.write,
                        id: info._id,
                      });
                    }}
                  >
                    {info.title}
                  </a>
                </td>
                <td className="date">{ConvertDate(info.regDate)}</td>
                <td className="status" style={{ color: "#99FF00" }}>
                  {info.isRead === "y" ? <p>확인</p> : <p>미확인</p>}
                </td>
                <td className="article-deletion">
                  <button
                    type="button"
                    className="btn btn-sm btn-remove-board"
                    aria-label="Left Align"
                    onClick={() => {
                      this.props.handleDel(info._id);
                    }}
                  >
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="bi bi-x-octagon-fill"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== noteView.write) {
        return <></>;
      }
      return (
        <div className="board-notice">
          <table
            className="board-bg-global board-bg-read board-page-read"
            style={{ width: "100%", border: "0" }}
            cellSpacing="0"
            cellPadding="0"
          >
            <thead>
              <tr>
                <td
                  className="title text-truncate"
                  scope="col"
                  style={{ width: "80%" }}
                >
                  {this.state.title}
                </td>
                <td className="date" scope="col" style={{ width: "20%" }}>
                  &nbsp;
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2} className="article">
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.contents }}
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="border-stroke-01"></div>
          <div className="board-controller">
            <p
              className="board-list-tool"
              style={{ width: "100%", height: "43px" }}
            >
              {" "}
            </p>
            <p className="board-list-tool">
              <button
                className="apply_btn btn-list"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  this.setState({
                    title: "",
                    contents: "",
                    mode: noteView.view,
                    id: "",
                  });
                }}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-card-text"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
                  ></path>
                </svg>
                <span>목록</span>
              </button>

              <button
                className="apply_btn btn-edit"
                onClick={() => {
                  this.props.handleDel(this.state.id);
                  this.setState({
                    title: "",
                    contents: "",
                    mode: noteView.view,
                    id: "",
                  });
                }}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-gear"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"
                  ></path>
                </svg>
                <span>삭제</span>
              </button>
            </p>
          </div>
        </div>
      );
    };

    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <div>
            <div
              className="modal fade show"
              id="board_notice-modal"
              aria-labelledby="board_notice-modalLabel"
              aria-modal="true"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title-wrapper">
                      <div className="modal-title-container">
                        <h5 className="title-text">쪽지</h5>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <span aria-hidden="true">
                        <img src="images/x-button.png" />
                      </span>
                    </button>
                  </div>
                  <div
                    className="modal-body"
                    style={{ width: "100%", height: "500px", overflow: "auto" }}
                  >
                    {/* Content Start */}

                    {RenderView()}
                    {RenderWrite()}

                    {/* Content End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
