import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  point: string;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: '0',
      point: '0',
    };
  }

  handleChangeToBalance = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                

              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
                
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">
                
                <ShareMenu view={popupView.point} handleChange={this.props.handleChange}></ShareMenu>


                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">금고</div>
                    <div className="con_box10">
                      <div className="money">
                        <ul>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            보유머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.balance)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            금고머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.point)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.props.updateUser();
                              }}
                            >
                              <span className="btn1_2">
                                <img src="/images/icon_re.png" height="20" />{" "}
                                새로고침
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="con_box10">
                      {/* Content start */}
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">금고입금</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                type="text"
                                id="reg_money"
                                name="reg_money"
                                value={this.state.balance}
                                onFocus={() =>  this.setState({balance: ''})}

                                onChange={(e) =>
                                  this.setState({
                                    balance: e.target.value,
                                  })
                                }
                                style={{ width: "250px" }}
                              />
                            </td>
                            <td className="write_basic">
                              <div className="btn_wrap_center">
                                <ul>
                                  <li>
                                    <a
                                      href="javascript:;"
                                      onClick={this.handleChangeToBalance}
                                    >
                                      <span className="btn3_1">입금하기</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">금고출금</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                type="text"
                                id="reg_money"
                                name="reg_money"
                                value={this.state.point}
                                onFocus={() =>  this.setState({point: ''})}
                                onChange={(e) =>
                                  this.setState({
                                    point: e.target.value,
                                  })
                                }
                                style={{ width: "250px" }}
                              />
                            </td>
                            <td className="write_basic">
                              <div className="btn_wrap_center">
                                <ul>
                                  <li>
                                    <a
                                      href="javascript:;"
                                      onClick={this.handleChangeToPoint}
                                    >
                                      <span className="btn3_1">출금하기</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* Content end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
