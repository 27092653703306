import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";

import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { ConverMoeny } from "../utility/help";




export enum popupView {
    none = "none",
    deposit = "deposit",
    withdraw = "withdraw",
    point = "point",
    notice = "notice",
    note = "note",
    help = "help",
    user = "user",
    reg = "reg",
    login = "login",
    comp = "comp",
    
  }

  

interface Props {

    view: string;
    handleChange: (viewName: string) => any;
  }
  
  interface State {
    notices: any;
  }
  

export class ShareMenu extends Component<Props, State> {
  
    constructor(props: Props) {
      super(props);
      this.state = { notices: [] };
    }
  
    componentDidMount() {
    }
  
    render() {
      let notices = this.state.notices;
      return (
      
        <div className="popup_tab_wrap">
          <ul className="popup_tab popup_tab1">
            <li className={this.props.view === popupView.deposit ? 'tab1 sk_tab_active_01' : 'tab1'}  data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.deposit); //입금신청
                }}
              >
                <span>입금신청</span>
              </a>
            </li>
            <li className={this.props.view === popupView.withdraw ? 'tab2 sk_tab_active_01' : 'tab2'}  data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.withdraw); //출금신청
                }}
              >
                <span>출금신청</span>
              </a>
            </li>
            <li className={this.props.view === popupView.point ? 'tab4 sk_tab_active_01' : 'tab4'} data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.point); //금고
                }}
              >
                <span>금고</span>
              </a>
            </li>
            <li className={this.props.view === popupView.comp ? 'tab5 sk_tab_active_01' : 'tab5'} data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.comp); //금고
                }}
              >
                <span>콤프</span>
              </a>
            </li>
            <li className={this.props.view === popupView.help ? 'tab5 sk_tab_active_01' : 'tab5'} data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.help); //고객센터
                }}
              >
                <span>고객센터</span>
              </a>
            </li>
            <li className={this.props.view === popupView.user ? 'tab6 sk_tab_active_01' : 'tab6'}
                data-target="#sk_tab_con_01_1">
              <a
                
                onClick={() => {
                  this.props.handleChange(popupView.user); //마이페이지
                }}
              >
                <span>마이페이지</span>
              </a>
            </li>
            <li
              className={this.props.view === popupView.notice ? 'tab7 sk_tab_active_01' : 'tab7'}
              data-target="#sk_tab_con_01_1"
            >
              <a >
                <span>공지사항</span>
              </a>
            </li>
          </ul>
        </div>
    );
    }
  }
  