import React, { Component } from "react";
import styled from "styled-components";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";

import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { ConverMoeny } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2 align="center" onClick={() => setOpen(!open)}>
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center">
          {ConvertDate(row.row)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { notices: [] };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });
  };

  render() {
    let notices = this.state.notices;
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">
                <div className="popup_tab_wrap">
                  <ul className="popup_tab popup_tab1">
                    <li className="tab1" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.deposit); //입금신청
                        }}
                      >
                        <span>입금신청</span>
                      </a>
                    </li>
                    <li className="tab2" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.withdraw); //출금신청
                        }}
                      >
                        <span>출금신청</span>
                      </a>
                    </li>
                    <li className="tab4" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.point); //금고
                        }}
                      >
                        <span>금고</span>
                      </a>
                    </li>
                    <li className="tab5" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.help); //고객센터
                        }}
                      >
                        <span>고객센터</span>
                      </a>
                    </li>
                    <li className="tab6" data-target="#sk_tab_con_01_1">
                      <a
                        href="javascript:;"
                        onClick={() => {
                          this.props.handleChange(popupView.user); //마이페이지
                        }}
                      >
                        <span>마이페이지</span>
                      </a>
                    </li>
                    <li
                      className="tab7 sk_tab_active_01"
                      data-target="#sk_tab_con_01_1"
                    >
                      <a href="javascript:;">
                        <span>공지사항</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title_wrap">
                      <div className="title">공지사항</div>
                    </div>
                    <div className="con_box10">
                      {/* Content start */}
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          aria-label="a dense table"
                          style={{ backgroundColor: "#692828" }}
                        >
                          <TableHead>
                            <TableRow>
                              <CustomTableCell align="center"></CustomTableCell>
                              <CustomTableCell align="center">
                                제 목
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                작성일
                              </CustomTableCell>
                              <CustomTableCell align="center">
                                상태
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {notices.map((row: any) => (
                              <Row key={row.name} row={row} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* Content end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
