import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";


export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export class Withdraw extends Component<Props, State> {
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);
    this.state = { balance: '0' };
  }

  handleDoWithdraw = () => {
    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "출금",
        message: "출금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }
    if (Number(this.state.balance) < 10000) {
      confirmAlert({
        title: "출금",
        message: "출금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserWithdrawV2(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "출금",
          message: "출금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "출금",
          message: "보유머니가 부족하거나 게임에 1회 접속하셨는지 확인바랍니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "출금",
          message: "대기중인 출금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "출금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };
  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div className="popup_wrap">
              <div className="close_box">
                <a
                  href="javascript:;"
                  className="sub_pop1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/images/popup_close.png" />
                </a>
              </div>
              <div className="popupbox">
              
                <ShareMenu view={popupView.withdraw} handleChange={this.props.handleChange}></ShareMenu>

                <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                  <div id="sk_tab_con_01_1" className="sk_tab_con_01">
                    <div className="title1">출금신청</div>
                    {/* <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">주의사항</div>
                        <div className="info3">
                          - 출금은 최소 3만원이상 출금신청 해주시기 바랍니다.
                          <br />- 출금은 입금 신청하실때 신청하신 계좌로만
                          출금이 가능합니다.
                        </div>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <div className="money">
                        <ul>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            보유머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.balance)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li style={{ width: "250px", textAlign: "left" }}>
                            <img src="/images/ww_icon.png" height="26" />{" "}
                            금고머니 :{" "}
                            <span className="font05" id="lnMoney">
                              {ConverMoeny(this.props.user.point)}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </li>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.props.updateUser();
                              }}
                            >
                              <span className="btn1_2">
                                <img src="/images/icon_re.png" height="20" />{" "}
                                새로고침
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="con_box10">
                      <table
                        style={{ width: "100%", border: "0" }}
                        cellSpacing="0"
                        cellPadding="0"
                        className="write_title_top"
                      >
                        <tbody>
                          <tr>
                            <td className="write_title">아이디</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                value={this.props.user.id}
                                readOnly
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="write_title">예금주명</td>
                            <td className="write_basic">
                              <input
                                className="input1 userName"
                                size={30}
                                value={this.props.user.bankowner}
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">회원은행</td>
                            <td className="write_basic">
                              <input
                                readOnly
                                className="input1 userBankName"
                                size={30}
                                value={this.props.user.bankname}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="write_title">신청금액</td>
                            <td className="write_basic">
                              <input
                                className="input1"
                                size={30}
                                id="deposit_amount"
                                placeholder="0"
                                value={this.state.balance}
                                onFocus={() =>  this.setState({balance: ''})}
                                onChange={(e) =>
                                  this.setState({
                                    balance: e.target.value,
                                  })
                                }
                              />
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 50000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">5만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 100000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">10만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 500000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">50만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 1000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">100만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 5000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">500만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({
                                    balance: `${Number(this.state.balance) + 10000000}`,
                                  });
                                }}
                              >
                                <span className="btn1_2">1000만원</span>
                              </a>
                              <a
                                href="javascript:;"
                                onClick={() => {
                                  this.setState({ balance: '0' });
                                }}
                              >
                                <span className="btn1_1">정정</span>
                              </a>
                              <br />
                              출금신청시 10,000원 단위이상 출금신청바랍니다.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="con_box20">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a
                              href="javascript:;"
                              onClick={this.handleDoWithdraw}
                            >
                              <span className="btn3_1">출금신청하기</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
