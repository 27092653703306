import React, { Component } from "react";

interface Props {}
interface State {}

export class Footer extends Component<Props, State> {
  render() {
    return (
      <>
        <div className="footer_wrap">
          <img src="/images/footer.png" />
        </div>
      </>
    );
  }
}
